import { Button } from '@mui/material';

export const StyledButton = ({ type, btnText, sx, onChange, className, onClick }) => {

    return (
        <Button
            onClick={onClick}
            type={type}>
            {btnText}
        </Button>
    )
}