
import { useEffect, useState } from "react";
import styled from "styled-components";

import { validateData } from "../../../layoutEngine/validator";
import { useDraftContext } from "../../../context/DraftDataContext";
import { performActions } from "../../../helpers/ActionExecuter";



  const StyledTextarea = styled.textarea`
    width: 100%;
    min-height: 30rem;
  `;

const TextAreaInput = (props) => {
    const { dataAttribute, validation, label, className, parentId, action, validate, myData, dProps, functions, nodata, data, style} = props
    const defaultValue =  nodata && data?.[parentId]?.[dataAttribute] !== '' ? data?.[parentId]?.[dataAttribute] : ''
    const [value, setValue] = useState()
    const { notifyChange, notifyError } = useDraftContext()

    useEffect(() => {
        setValue(defaultValue)
      }, [defaultValue]);

      const onChange = (obj) => {
        notifyChange({ ...obj, parentId })
        if(action?.onChange) performActions(action.onChange, functions)
        setValue(obj.value)
      }

      const validateField = (value, attr, constraints) => {
        const valid = validateData(value, attr, constraints)
        notifyError(attr, { error: valid, msg: constraints?.message })
        if (action?.validate || validate) {
          if (!valid) return {}
          return {
            error: true,
            helperText: constraints?.message
          }
        }
      }

  return (
    <>
    <label style={style}>{label}</label>
    <StyledTextarea className={className} key={dataAttribute}
        onChange={(evt) =>
          onChange({ value: evt.target.value, dataAttribute })
        }
        value={value}
        {...validateField(value, dataAttribute, validation)}
        style={{marginTop: "10px"}}
      ></StyledTextarea>
    </>
  )
}

export default TextAreaInput;
