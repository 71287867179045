import React from "react";
import { DraftDataContext } from "../context/DraftDataContext";
import { ActionsContext } from "../context/ActionsContext";
import { PrompterV2 } from "./indexV2";
import { ThemeContext } from "../context/ThemeContext";

export const Prompter = () => {
  return (
    <DraftDataContext>
      <ActionsContext>
        <ThemeContext>
          <PrompterV2 />
        </ThemeContext>
      </ActionsContext>
    </DraftDataContext>
  );
};
