import { useState } from "react";
import { validateData } from "../../layoutEngine/validator";
import Checkbox from "@mui/material/Checkbox";
import { FormLabel, FormControlLabel } from "@mui/material";
import { useDraftContext } from "../../context/DraftDataContext";

export default function CheckBox(props) {
    const { dataAttribute, validation, defaultValue, label, parentId } = props

    const [value, setValue] = useState(defaultValue || '')
    const { notifyChange } = useDraftContext()

    const onChange = (obj) => {
        notifyChange({ ...obj, parentId })
        setValue(obj.value)
    }

    const validateField = (value, attr, constraints) => {
        const valid = validateData(value, attr, constraints)
        if (!valid) return {}
        return {
            error: true,
            helperText: constraints?.message
        }
    }

    return (
        <div className="form_field">
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={(evt) => onChange({ value: evt.target.value, dataAttribute })}
                        name={value}
                        {...validateField(value, dataAttribute, validation)}
                    />
                }
                label={label}
            />
        </div>
    )

}