import React from 'react';
import { Box as MUIBox } from "@mui/material";
import { layout } from '../../layoutEngine/layout';

const Box = (props) => {
    const { style, children, functions, parentId, myData, nodata } = props;

    return (
        <MUIBox style={style}>
            {layout({ui: children, functions, parentId}, myData, nodata)}
        </MUIBox>
    );
};

export default Box;