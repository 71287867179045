import { layout } from './layout';

const LayoutLoader = (props) => {
    const { metadata, myData, functions, parentId, nodata } = props
    function renderLayout() {
        const layloutData = functions ? {...metadata.layout , functions, parentId} : {...metadata.layout, parentId }
        return layout(layloutData, myData, nodata)
    }
    return renderLayout()
}

export { LayoutLoader };
