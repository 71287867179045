import "./dynamicForm.css";

import { FormStepper, Dropdown, TextInput, CheckBox, ButtonComponent, TextAreaInput, Radio, TabPanel, AutoComplete, CheckboxGroup } from '../sharedComponents/components'
import Grid from "../sharedComponents/components/Grid";
import Typography from "../sharedComponents/components/Typography";
import Box from "../sharedComponents/components/Box";
import OpenAIImage from "../images/image.png";
import Preview from "../sharedComponents/components/Preview";
import { resolveRule } from "../helpers/utils";
import JEDIT from "../sharedComponents/components/JSONEditor";



const layout = (layoutData, myData, nodata) => {
    const { ui, data, parentId } = layoutData
    function getRules(uiElement) {
        Object.keys(uiElement).map(key => {
            const rule = uiElement[key]
            if (typeof rule === 'string' && rule?.startsWith("!!") && (myData || data)) {
                const result = resolveRule(rule, { myData: myData, allData: data, propName: null }, uiElement.defaultValue, uiElement.functions)
                Object.assign(uiElement, result)
            }
            return uiElement
        })
    }


    function renderUIElements(uiElements) {
        if (typeof uiElements === 'string') return <>uiElements</>
        return uiElements.map(uiElement => {
            const {
                displayType,
                // dProps,
                // label,
                // className,
                // variant,
                // type,
                // options,
                // action,
                // functions,
                // validate,
                // validation,
                // dataAttribute,
            } = uiElement

            // const validateField = (value, attr, constraints) => {
            //   const valid = validateData(value, attr, constraints)
            //   if (!valid) return {}
            //   return {
            //     error: true,
            //     helperText: constraints?.message
            //   }
            // }
             getRules(uiElement)
            const componentsProps = { ...uiElement, data: data || uiElement.data, functions: uiElement.functions || layoutData.functions, parentId, myData, nodata }
            switch (displayType) {
                case "formStepper":
                    return <FormStepper  {...componentsProps}></FormStepper>
                case "text":
                    return <TextInput  {...componentsProps}></TextInput>
                case "select":
                    return <Dropdown  {...componentsProps}></Dropdown>
                case "autocomplete":
                    return <AutoComplete  {...componentsProps}></AutoComplete>
                case "checkboxGroup":
                    return <CheckboxGroup  {...componentsProps}></CheckboxGroup>
                case "checkbox":
                    return <CheckBox  {...componentsProps}></CheckBox>
                case "button":
                    return <ButtonComponent  {...componentsProps}></ButtonComponent>
                case "grid":
                    return <Grid  {...componentsProps}></Grid>
                case "box":
                    return <Box  {...componentsProps}></Box>
                case "footer":
                    return <></>
                case "typography":
                case "label":
                    return <Typography  {...componentsProps}></Typography>
                case "preview":
                    return <Preview  {...componentsProps} myData={myData}></Preview>
                case "jEditor":
                    return <JEDIT  {...componentsProps} myData={myData}></JEDIT>
                case 'textarea':
                    return <TextAreaInput {...componentsProps}></TextAreaInput>
                case "img":
                    return <img alt="OpenAI" src={OpenAIImage} className="img1" />
                case 'tabs':
                    return <TabPanel {...componentsProps} ></TabPanel>
                case "radio":
                    return <Radio  {...componentsProps}></Radio>
                default:
                    return <></>
            }
        })
    }

    return renderUIElements(ui)


};

export { layout };
