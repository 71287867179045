import { Grid } from "@mui/material";
import { Typography, Link, List } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import PlaceIcon from '@mui/icons-material/Place';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import SearchIcon from '@mui/icons-material/Search';

function FooterLinks() {
    const everydayBankingList = ['Accounts', 'Private Banking Services', 'Compare SAB Credit Cards', 'Compare SAB Co-Brand Credit Cards', 'Cashback Credit Card', 'SAB Travel Card', 'SAB Debit Card']
    const wealthManagement = ['SAB knowledge centre', 'Wealth Management Solutions', 'Investment Solutions', 'Wide Range Asset Classes', 'Safe Deposit Lockers']
    const financing = ['Personal Finance', 'Fiancing against Pledge of Cash Deposits Product', 'Home Fiance Products', 'Non-salary Transfer Personal Finance']
    const paymentsAndTransfers = ['Payments', 'Local Transfers']

return (<>
    <Grid>
        <Grid>
            <Grid container xs={12} style={{ backgroundColor: 'rgb(128 128 128)', padding: '25px' }}>
               <Grid container xs={9}>
                    <Typography color="white" style={{ fontWeight: 'bold', marginRight: '60px',marginLeft: '40px', alignItems: 'center', display: 'flex' }}>
                        <CallIcon sx={{ marginRight: '5px' }} /> Contact SAB
                    </Typography>


                    <Typography color="white" style={{ fontWeight: 'bold', marginRight: '60px', marginLeft: '40px',alignItems: 'center', display: 'flex' }}>
                        <PlaceIcon sx={{ marginRight: '5px' }} />Find a Branch or ATM
                    </Typography>

                    <Typography color="white" style={{ fontWeight: 'bold', marginRight: '60px',marginLeft: '40px', alignItems: 'center', display: 'flex' }}>
                        <ChatBubbleIcon sx={{ marginRight: '5px' }} />Careers
                    </Typography>

                    <Typography color="white" style={{ fontWeight: 'bold', marginRight: '60px', marginLeft: '40px',alignItems: 'center', display: 'flex' }}>
                        <SearchIcon sx={{ marginRight: '5px' }} /> Search
                    </Typography>
                </Grid>
                <Grid container xs={3}>
                    <Typography color="white" style={{ fontWeight: 'bold', marginRight: '20px', marginLeft: '40px', alignItems: 'center', display: 'flex' }}>
                        <CallIcon sx={{ marginRight: '5px' }} />  For Emergencies 500 124 
                    </Typography>
                </Grid> 
            </Grid>
        </Grid>
        <Grid container xs={12} style={{ backgroundColor: '#4c4c4c', padding: '50px 10px' }}>
             <Grid item xs={3}>
                <Typography variant="h5" color="white" style={{ fontWeight: 'bold' , marginRight: '20px', marginLeft: '60px'}}>
                    Everyday Banking
                </Typography>
                <List sx={{ display: "grid", marginRight: '20px', marginLeft: '60px' }}>
                    {
                        everydayBankingList.map((each) => {
                            return (<>
                                <Link href="#" underline="hover" color="white">
                                    {each}
                                </Link>
                            </>)
                        })
                    }
                </List>

            </Grid>
            <Grid item xs={3}>
                <Typography variant="h5" color="white" style={{ fontWeight: 'bold', marginRight: '20px', marginLeft: '60px' }}>
                    Wealth Management Financing
                </Typography>
                <List sx={{ display: "grid" , marginRight: '20px', marginLeft: '60px' }}>
                    {
                        wealthManagement.map((each) => {
                            return (<>
                                <Link href="#" underline="hover" color="white">
                                    {each}
                                </Link>
                            </>)
                        })
                    }
                </List>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="h5" color="white" style={{ fontWeight: 'bold', marginRight: '20px', marginLeft: '60px' }}>
                    Financing
                </Typography>
                <List sx={{ display: "grid" , marginRight: '20px', marginLeft: '60px'}}>
                    {
                        financing.map((each) => {
                            return (<>
                                <Link href="#" underline="hover" color="white">
                                    {each}
                                </Link>
                            </>)
                        })
                    }
                </List>
            </Grid> 
             <Grid item xs={3}>
                <Typography variant="h5" color="white" style={{ fontWeight: 'bold' , marginRight: '20px', marginLeft: '60px'}}>
                    Payments & Transfers
                </Typography>
                <List sx={{ display: "grid", marginRight: '20px', marginLeft: '60px' }}>
                    {
                        paymentsAndTransfers.map((each) => {
                            return (<>
                                <Link href="#" underline="hover" color="white">
                                    {each}
                                </Link>
                            </>)
                        })
                    }
                </List>
            </Grid> 
        </Grid>
    </Grid >
</>)
}

export default FooterLinks;