import config from '../config/config';
import { get } from './dataServices';

export const fetchPrompts = async () => {
    try {
        const response = await get(config.listPrompt);

        return response.data;
    } catch (error) {
        alert("Error in fetching prompts: ", error)
        console.error('Error fetching prompts:', error);
        return null;
    }
};

