// config.js

// Development environment configuration
const devConfig = {
    createPrompt : "https://mazizhls58.execute-api.eu-west-2.amazonaws.com/prod/create-prompt",
    getCreatePromptResponse : "https://mazizhls58.execute-api.eu-west-2.amazonaws.com/prod/get-create-prompt-response",
    uploadPrompt : "https://mazizhls58.execute-api.eu-west-2.amazonaws.com/prod/upload",
    listPrompt : "https://mazizhls58.execute-api.eu-west-2.amazonaws.com/prod/list-prompts",
    getPrompt: "https://mazizhls58.execute-api.eu-west-2.amazonaws.com/prod/get-metadata"
};

// Production environment configuration
const prodConfig = {
    createPrompt : "https://mazizhls58.execute-api.eu-west-2.amazonaws.com/prod/create-prompt",
    getCreatePromptResponse : "https://mazizhls58.execute-api.eu-west-2.amazonaws.com/prod/get-create-prompt-response",
    uploadPrompt : "https://mazizhls58.execute-api.eu-west-2.amazonaws.com/prod/upload",
    listPrompt : "https://mazizhls58.execute-api.eu-west-2.amazonaws.com/prod/list-prompts",
    getPrompt: "https://mazizhls58.execute-api.eu-west-2.amazonaws.com/prod/get-metadata"
};

// Determine the current environment
const env = process.env.NODE_ENV || 'development';

// Select the appropriate configuration based on the environment
let config;
if (env === 'production') {
    config = prodConfig;
} else {
    config = devConfig;
}

// Export the configuration object
module.exports = config;