import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { layout } from "../../layoutEngine/layout";
import { useDraftContext } from "../../context/DraftDataContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const TabSections = (props) => {
  const { functions, parentId, myData, nodata, tabs } = props;
  const { notifyChange, notifyError } = useDraftContext();


  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    functions.onTabChange();
    notifyChange({parentId})
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={value} onChange={handleChange} aria-label="Prompts Options">
        {tabs.map((tab, index) => (
          <Tab label={tab.label} key={index} />
        ))}
      </Tabs>
      
      {tabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index}>
          {layout({ ui: tab.children, functions, parentId }, myData, nodata)}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabSections;
