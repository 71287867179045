import React from 'react';
import { useLocation } from 'react-router-dom';
import { DynamicPage } from '../components/dynamicPage';
import { LayoutLoader } from '../layoutEngine/layoutLoader';
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';

const ResultPage = () => {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();

  const pageRedirect = () => {
    const url = `/admin/prompterV2`
    navigate(url);
}

  return (
    <div>
      <h1>Preview Page</h1>
      <Button variant="contained" size="large" onClick={pageRedirect} >
        Back
      </Button>
      
      {state?.metadata ? (
       <DynamicPage>
       <LayoutLoader id="app" myData={state} metadata={state?.metadata?.metadata}   parentId="home" />
     </DynamicPage>
      ) : (
        <p>No data available.</p>
      )}
    </div>
  );
};

export default ResultPage;