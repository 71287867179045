import React, { useState } from "react";
import config from "../config/config";
import { DynamicPage } from "../components/dynamicPage";
import { LayoutLoader } from "../layoutEngine/layoutLoader";
import { Grid, Box } from "@mui/material";
import { Typography, Alert } from "@mui/material";
import { setLS, getLS, validateJson } from "../helpers/utils";

import { TextareaComp } from "../components/TextareaComp";
import { StyledButton } from "../components/StyledButton";
import OpenAIImage from "../images/image.png";
import { InputField } from "../components/InputField";
import { Dropdown } from "../sharedComponents/components";
import { post } from "../services/dataServices";
import "./index.css";

export const Prompter = () => {
  const { createPrompt, uploadPrompt } = config;
  const [promptData, setPromptData] = useState("");
  const [response, setResponse] = useState("");
  const [prompts, setPrompts] = useState(getLS("prompts") || []);

  const setProptLS = ({ name, prompt, metadata }) => {
    let ls = getLS("prompts") || [];
    ls = ls.filter((item) => item.value !== name);
    ls.push({
      value: name,
      label: name,
      prompt,
      metadata: JSON.parse(metadata),
    });
    setLS("prompts", ls);
    setPrompts(ls);
  };

  const submitPrompt = () => {
    post(createPrompt, { name: promptData.name, prompt: promptData.prompt })
      .then((res) => {
        setPromptData({ ...res.data, metadata: JSON.parse(res.data.metadata) });
        setProptLS(res.data);
      })
      .catch((err) => console.log(err));
  };

  const saveMetadata = () => {
    post(uploadPrompt, {
      ...promptData,
      metadata: JSON.stringify(promptData.metadata),
    })
      .then((res) => {
        setResponse("Metadata saved!");
      })
      .catch((err) => console.log(err));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box className="box1">
          <img alt="OpenAI logo" src={OpenAIImage} className="img1" />
          <Typography className="th1" level="h1">
            Welcome Alex
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} className="marginBottom20">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            
            <Box className="box3">
              <InputField
                label={"Feature name"}
                value={promptData.value}
                minRows={3}
                handleChange={(e) => {
                  setPromptData({ ...promptData, name: e });
                }}
              />
              <InputField
                label={"Feature requirement"}
                value={promptData.prompt}
                minRows={3}
                handleChange={(e) => {
                  setPromptData({ ...promptData, prompt: e });
                }}
              />
            </Box>
            <StyledButton
              sx={{ margin: "50px 20px" }}
              btnText="Submit Prompt"
              onClick={() => submitPrompt()}
            />
            <Box className="box4">
              {response && (
                <Alert
                  sx={{
                    border: "1px solid #0a470a",
                  }}
                  color="success"
                  variant="soft"
                >
                  {response}
                </Alert>
              )}
            </Box>
            <TextareaComp
              value={JSON.stringify(promptData.metadata, null, 4)}
              minRows={20}
              handleChange={(e) => {
                setPromptData({
                  ...promptData,
                  metadata: JSON.parse(e.target.value),
                });
              }}
            />

            <StyledButton
              sx={{ margin: "50px 20px" }}
              btnText="Save Metadata"
              onClick={() => saveMetadata()}
            />
          </Grid>

          <Grid item xs={8}>
            <label
              style={{
                fontSize: "18px",
                marginBottom: "10px",
                fontWeight: 500,
                marginLeft: "20px",
              }}
            >
              Preview
            </label>

            <Grid
              item
              xs={12}
              sx={{
                border: "1px solid #c8c4cc",
                margin: "12px 20px",
                height: "100%",
                borderRadius: "5px",
              }}
            >
              {promptData.metadata &&
                validateJson(JSON.stringify(promptData.metadata)) && (
                  <DynamicPage>
                    <LayoutLoader id="promIndex" {...promptData.metadata} />
                  </DynamicPage>
                )}
              {promptData.metadata &&
                validateJson(JSON.stringify(promptData.metadata)) === false && (
                  <p>Invalid JSON</p>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
