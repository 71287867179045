import image1 from "../images/image1.png";
import image2 from "../images/image2.png";
import image3 from "../images/image3.png";
import { Grid } from "@mui/material";
import printerIcon from "../images/Printer.png";
import shareNetworkIcon from "../images/ShareNetwork.png";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import "../App.css";
import FooterLinks from './footerLinks'
import { minHeight } from "@mui/system";

function AppFooter() {
    const content1 = 'Secure your familys future with Asparagus Bank financial insurance solutions.';
    const content2 = 'Discover exclusive benefits and tailored financial solutions for senior clients.';
    const content3 = 'Ensure worry-free adventures with secure financial transactions while exploring the world.';
    const learnMore = 'Learn more';
    function onLearnMoreClick() {
        console.log("Learn more clicked...");
    }

    return (
        <footer className="footer">
          
           <Grid container className="items" style={{ marginBottom:'30px' , marginTop:'40px'}}>
                <Grid item xs={3}>
                    <Card style={{ position: "relative",display: 'block',marginLeft: '85px', marginRight: 'auto',width: '70%' }}>
                        <CardMedia component="img" alt="image 1" image={image1} />
                        <Typography variant="h7" color="black" fontWeight='bold' fontSize='13px'>
                                 {content1} 
                                  <CardActions style={{ position: "relative", right:'2%',top:'5px', 
                               color: "black", fontWeight:'bold' }}>
                            <u>{learnMore}</u> 
                         </CardActions>  
                             </Typography>
                    </Card>
                </Grid>

                <Grid item xs={3}>
                    <Card style={{ position: "relative",  display: 'block',marginLeft: 'auto', marginRight: 'auto',width: '70%' }}>
                        <CardMedia component="img" alt="image 2" image={image2} />
                        <Typography variant="h7" color="black" fontWeight='bold' fontSize='13px'>
                                     {content2}
                             <CardActions style={{ position: "relative", right:'2%',top:'5px',
                               color: "black", fontWeight:'bold' }}>
                            <u>{learnMore}</u> 
                         </CardActions>
                         </Typography>
                    </Card>
                </Grid>

                <Grid item xs={3}>
                    <Card style={{ position: "relative" ,display: 'block',marginLeft: 'auto', marginRight: '85px',width: '70%' }}>
                        <CardMedia component="img" alt="image 3" image={image3} />
                        <Typography variant="h7" color="black"  fontWeight='bold' fontSize='13px'>
                                    {content3}
                             <CardActions style={{ position: "relative", right:'2%', top:'5px',
                              color: "black", fontWeight:'bold' }}>
                           <u>{learnMore}</u> 
                        </CardActions>
                        </Typography>
                    </Card>
                </Grid>
            </Grid>

            {/* <img className="footer-image" src={footerImage} /> */}
            <FooterLinks />
        </footer>
    );
}

export default AppFooter;
