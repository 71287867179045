import React, { useEffect, useState } from "react";

import { DynamicPage } from "./components/dynamicPage";
import { useLocation, useParams } from "react-router-dom";
import stub from "./metadata/metadata-v2.json";
import { LayoutLoader } from "./layoutEngine/layoutLoader";
import { post } from './services/dataServices'
import { load } from "./services/externalDataLoader";
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./auth/protectedRoute"
import { LoginPage } from "./auth/login";
import { Prompter as PrompterV2 } from "./prompter/prompter";
import { Prompter } from "./prompter";
import config from "./config/config";
import ResultPage from "./prompter/renderpage";
import Radio from "./sharedComponents/components/RadioButton";

const localStub = false

function App() {
  const location = useLocation();
  const { getPrompt } = config;
  const [metadata, setMetadata] = useState();
  const [currentRoute, setCurrentRoute] = useState();
  const [initialData, setInitialData] = useState();
  const [error, setError] = useState();

  const params = useParams();


  function errorHandler(error) {
    console.log('Error occurred', error)
    setError(error)
  }

  const loadExternalData = (data, done) => {
    load(data, (response) => {
      done(response)
    })
  }

  function getInitialData(data) {
    if (!data) return
    if (data.data) {
      loadExternalData(data.data, (response) => {
        setInitialData({ metadata: data, externalData: response })
      })
    } else {
      setInitialData({ metadata: data })
    }
  }

  useEffect(() => {
    setCurrentRoute(location.pathname.split("/")[1])
  }, [location.pathname])

  useEffect(() => {
    
    // post(getPrompt, {'name': value})
    // .then(res => {
    //   const resMetaData = res.data?.body;
    //   setMyData({ ...myData, name: resMetaData?.aspName, prompt: resMetaData?.aspPrompt?.prompt, metadata: resMetaData.aspMetaData});
    // });

      // async function getAppData() {
      //   if (localStub) {
      //     getInitialData(stub.metadata)
      //     setMetadata(stub.metadata)
      //   } else {
      //     const body = JSON.stringify({ metadata: location.pathname.split("/")[1] })
      //     debugger;
      //     const response = await post(`https://mazizhls58.execute-api.eu-west-2.amazonaws.com/prod/get-metadata`, body, null, errorHandler)
      //     if (response && response.data?.metadata) {
      //       const data = JSON.parse(response.data.metadata)
      //       const metadata = data?.metadata
      //       if (metadata) {
      //         getInitialData(metadata)
      //         setMetadata(metadata)
      //       }
      //     }

      //   }
      // }
      // getAppData()
  }, [currentRoute]);

  const renderPage = () => {
    const data = location.state?.data;

    if (metadata && initialData) return (
      <DynamicPage>
        <LayoutLoader id="app" {...initialData} parentId="home" />
      </DynamicPage>
    )
    if (error) return <>Error occurred while fetching metadata</>
  }


  return (
    <Routes>
      <Route path="/admin/login" element={<LoginPage />} />
      <Route
        path="/admin/prompter"
        element={
          <ProtectedRoute>
            <Prompter />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/prompterv2"
        element={
          <ProtectedRoute>
            <PrompterV2 />
          </ProtectedRoute>
        }
      />
      <Route path="/:metadata" element={<ResultPage/>} />
      <Route path="/" to="/details" />
    </Routes>
    
  )
}

export default App;
