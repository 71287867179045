import React from 'react';
import { Typography as MUITypography } from "@mui/material";

const Typography = (props) => {
    const { style, children, dProps } = props;
    const { variant } = dProps || {};
    
    if(!children) return null
    return (
        <MUITypography style={style} level={variant}>
            {children}
        </MUITypography>
    );
};

export default Typography;