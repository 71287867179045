import { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import TextField from "@mui/material/TextField";
import { Box } from '@mui/material';
import Button from "@mui/material/Button";
import OpenAIImage from '../images/image.png'
import { Typography, Link } from '@mui/material';

import { TextInput } from "../sharedComponents/components";

import { colors } from '../theme/color';

export const LoginPage = () => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { login } = useAuth();

    const handleLogin = async (e) => {
        e.preventDefault();
        if ((username === "user" || username === "user1") && password === "password") {
            await login({ username });
        } else {
            alert("Invalid username or password");
        }
    };
    return (
        <>
            <Box sx={{
                margin: "auto",
                position: "relative"
            }}>
                <img alt="openAI" src={OpenAIImage} style={{ width: '100%', filter: "brightness(60%)" }} />
                <Typography
                    color={colors.white}
                    variant="heading" sx={{
                        position: "absolute",
                        top: "70%",
                        left: "7%",
                    }}>Admin Panel</Typography>
            </Box>
            <Box sx={{
                width: '30%',
                padding: '100px',
                margin: "auto"
            }}>
                <form onSubmit={handleLogin}>
                    <TextField
                        type='text'
                        id="username"
                        label='Username'
                        className="form-field  form-right"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        type='password'
                        id='password'
                        label='Password'
                        className="form-field  form-right"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Link
                        sx={{ color: colors.black, "&:hover": { color: colors.purpleColor }, cursor: "pointer" }}
                    >Forgot your password?</Link>
                    <Box sx={{ textAlign: 'center' }} >
                        <Button
                            type='submit'
                            style={{backgroundColor: colors.purpleColor, color : colors.white, boxShadow: "none"}}
                        >Login</Button>
                    </Box>
                </form>
            </Box >
        </>
    );
};
