import React, { useEffect, useState, useRef } from "react";
import { layout } from "../../layoutEngine/layout";
import { useDraftContext } from "../../context/DraftDataContext";
import { useNavigate } from "react-router";
import arrow from "../../images/arrow.png";
import { Box } from "@mui/material";

export default function FormStepper(props) {
    const { children = [], nodata, parentId } = props;
    const { getError, getDraftData, postData } = useDraftContext();
    const formRef = useRef(null);

    let initialStep = 0;
    const [activeStep, setActiveStep] = useState(initialStep);
    const [validate, setValidate] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const navigate = useNavigate();
    const draftData = getDraftData();
    let activeNavigation;

    const handleNav = (index, label) => {
        setActiveStep(index);
        activeNavigation = label;
    };

    useEffect(() => {
        // Update the child's state when the parent's prop (data) changes
        setActiveStep(initialStep);
    }, [parentId]);

    console.log({ parentId })

    function renderNavigation() {
        if (children) {
            children.map((child, index) => {
                const { label } = child;
                if (index === activeStep) {
                    activeNavigation = label;
                }
            });
            return children.map((child, index) => {
                const { label } = child;
                return (
                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                        <div style={{ width: "75%" }}>
                            <li className="sectionLI">
                                <a
                                    className={
                                        activeStep === index ? "active-step" : "inactive-step"
                                    }
                                    target="_blank"
                                    onClick={() => handleNav(index, label)}
                                >
                                    {label}
                                </a>
                            </li>
                        </div>
                        <div style={{ width: "25%", marginBottom: "30px" }}>
                            {activeStep === index ? (
                                <img src={arrow} style={{ width: "100%", height: "10px" }} />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                );
            });
        }
    }

    function validateForm(btn, callback) {
        setValidate(true);
        if (callback) {
            callback();
        }
    }

    function handleNextStep(btn) {
        if (getError()) return;

        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        setValidate(false);
        setActiveStep(activeStep + 1);
    }

    function handlePreviousStep(btn) {
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        setValidate(true);
        setActiveStep(activeStep - 1);
    }


    function handleSubmit(vale, callback) {
        if (getError()) return;
        alert("Data Submitted")
        // postData(getDraftData(), () => {
        //   if (callback) {
        //     callback();
        //   }
        //   // setDataSubmitted(true)
        // });
    }

    function handleNavigate(value) {
        if (getError()) return;
        navigate(value);
    }

    function renderSections() {
        const section = children[activeStep].children.map((section) => ({
            ...section,
            validate,
            parentId: children?.[activeStep]?.id,
        }));
        return layout({ ui: section, data: draftData, parentId }, null, nodata);
    }

    function renderButtons(option) {
        const functions = {
            validateForm,
            handleNextStep,
            handlePreviousStep,
            handleSubmit,
            handleNavigate,
        };

        const buttons = children[activeStep].children.filter(
            (item) => item.displayType === "footer" && item.className === option
        );

        if (buttons.length !== 0) {
            const button = buttons[0]?.children.map((child) => ({
                ...child,
                functions,
            }));

            return layout({ ui: button, data: draftData, parentId }, null, nodata);
        }
        return false;
    }

    return (
        <div style={{ display: "flex", width: "100%" }} ref={formRef}>
            {dataSubmitted && <div style={{ width: "100%" }}>Data Submitted</div>}
            {!dataSubmitted && (
                <div style={{ width: "25%" }}>{renderNavigation()}</div>
            )}
            {!dataSubmitted && (
                <div
                    style={{
                        width: "75%",
                        border: "2px solid #7B7878",
                        borderRadius: "12px",
                        padding: "15px 25px",
                    }}
                >
                    <div style={{ display: "block" }}>
                        <label
                            style={{ fontSize: "25px", fontWeight: "bold", color: "#71BF4F" }}
                        >
                            {activeNavigation}
                        </label>
                        {renderSections()}
                    </div>
                    <Box display="flex" justifyContent="space-between">
                        <div>{renderButtons("footerButtonLeft")}</div>
                        <div>{renderButtons("footerButtonRight")}</div>
                    </Box>
                </div>
            )}
        </div>
    );
}
