import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";

import { ThemeContext } from "./context/ThemeContext";
import { DraftDataContext } from "./context/DraftDataContext";
import { ActionsContext } from "./context/ActionsContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <DraftDataContext>
            <ActionsContext>
                <ThemeContext>
                    <BrowserRouter>
                        <AuthProvider>
                            <App/>
                        </AuthProvider>

                    </BrowserRouter>
                </ThemeContext>
            </ActionsContext>
        </DraftDataContext>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
