import React from 'react';
import { Grid as MUIGrid } from "@mui/material";
import { layout } from '../../layoutEngine/layout';

const Grid = (props) => {
    const { dataAttribute, children, functions, parentId, myData, nodata, dProps: {
        type,
        xs,
        style
    } } = props;

    const container = type === "container" ? true : false;
    const item = type === "item" ? true : false;

    return (
        <MUIGrid key={dataAttribute} container={container} item={item} xs={xs} style={style}>
            {layout({ ui: children, functions, parentId }, myData, nodata)}
        </MUIGrid>
    );
};

export default Grid;