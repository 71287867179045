import Button from "@mui/material/Button";
import { performActions } from "../../helpers/ActionExecuter";

function ButtonComponent(props) {
  const { label, className, action, variant, type, functions, style } = props;
  /**
  const performActions = (actions, functions) => {
    const actionsArr = actions.split('>')
    let counter = 0

    const execute = (action) => {
      if (action.startsWith('^')) {
        const parentAction = action.slice(1, action.length)
        functions[parentAction]('layoutAction-' + parentAction, () => {
          counter = + 1
          if (actionsArr[counter]) {
            execute(actionsArr[counter])
          }
        })
      } else {
        action()
      }
    }
    execute(actionsArr[0])
  }
   */

  return (
    <Button
      className={className}
      variant= {variant}
      primary
      onClick={() => performActions(action.onClick, functions, action.value, props)}
      type={type}
      style={style}
    >
      {label}
    </Button>
  );
}

export default ButtonComponent;
