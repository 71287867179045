import "../App.css";
import { useEffect } from 'react';
import { useActionsContext } from "../context/ActionsContext";
import AppHeader from './header';
import AppFooter from './footer';
import Grid from "@mui/material/Grid";

export const DynamicPage = (props) => {
  const { subscribe, unsubscribe } = useActionsContext()
  const subscriberId = 'dynamicPage'

  useEffect(() => {
    subscribe(subscriberId, (msg) => {
      if(msg.dataChange) {
        console.log('update appdata on change')
      }
    })
    return () => unsubscribe(subscriberId)
  })

  function renderChildren() {
    return (
      {...props.children}
    )
  }
  return (
    <div className="App">
      <AppHeader/>
      <section className="section">
        <Grid item xs={8} style={{maxWidth: "100%"}}>
          {renderChildren()}
        </Grid>
        </section>
      <AppFooter/>
    </div>
  );
};
