import axios from 'axios'


const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    document.body.style.cursor = 'progress';
    return config;
  },
  (error) => {
    document.body.style.cursor = 'default';
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    document.body.style.cursor = 'default';
    return response;
  },
  (error) => {
    document.body.style.cursor = 'default';
    return Promise.reject(error);
  }
);

export async function get(url, onError) {
  try {
    const response = await axiosInstance.get(url);
    return response
  } catch (error) {
    console.log(error);
    if (onError) onError(error)
  }
};


export async function post(url, data, headers, onError) {
  try {

    const response = await axiosInstance({
      method: 'POST',
      url,
      data,
      mode: "cors",
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
    });


    return response;
  } catch (error) {
    if (onError) {
      onError(error);
    } else {
      return error;
    }
  }
}

