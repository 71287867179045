import { Box } from '@mui/material';


import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';



export const TextareaComp = ({ id, label, minRows, value, type, sx, className, onChange, handleChange }) => {
    const [dvalue, setDValue] = useState('')
    useEffect(() => {
        if(dvalue !== value) {
            setDValue(value)
        }
    }, [value, dvalue])

    return (
        <Box sx={{
            width: '90%',
            // padding: '20px 30px',
            margin: '10px 10px 5px 10px',
            paddingLeft: '40px',
            display: 'grid'

        }}>
            <label
                htmlFor={id}
                style={{
                    fontSize: "18px",
                    marginBottom: "10px",
                    fontWeight: 500

                }}

            >{label} </label>
            <TextField

                minRows={minRows}
                multiline
                rows={10}
                variant="outlined"
                id={id}
                value={dvalue}
                onChange={(e) => {
                    setDValue(e.target.value)
                    handleChange(e)
                }}
            />

        </Box>
    )
}