import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export async function getAppTheme(type) {
    let appTheme = null
    switch (type) {
        case 'light':
            appTheme = await import('./ThemeLight/index')
            break;
        case 'dark':
            appTheme = await import('./ThemeDark/index')
            break;
        case 'custom':
            appTheme = await import('./Custom/index')
            break;
        default:
            appTheme = {}
            break;
    }

    const theme = createTheme(appTheme.themeOptions);
    
    return responsiveFontSizes(theme);

}

