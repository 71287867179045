import { useEffect, useRef, useState } from "react";
import { validateData } from "../../layoutEngine/validator";
import Checkbox from "@mui/material/Checkbox";
import { FormControl, FormLabel, FormControlLabel, FormGroup } from "@mui/material";
import { useDraftContext } from "../../context/DraftDataContext";
import FormHelperText from '@mui/material/FormHelperText';

export default function CheckboxGroup(props) {
    const { dataAttribute, validation, defaultValue, label, parentId, options } = props

    const [listOfValues, setListOfValues] = useState(options.map((each, index) => {
        return { label: each.label, value: each.value, isSelected: false };
    }))
    const { notifyChange } = useDraftContext()

    const onChange = (obj) => {
        console.log("Obj", obj)
        notifyChange({ ...obj, parentId })

    }

    const validateField = (value, attr, constraints) => {
        const valid = validateData(value, attr, constraints)
        if (!valid) return {}
        return {
            error: true,
            helperText: constraints?.message
        }
    }

    useEffect(() => {
        setListOfValues(options.map((each, index) => {
            return { label: each.label, value: each.value, isSelected: false };
        }));

    }, [options])

    return (
        <div className="form_field">

            <FormLabel style={{ marginTop: '25px' }}>{label}</FormLabel>
            <FormGroup>
                {options.map((option, index) => (
                    (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    checked={option.isSelected}
                                    onChange={(evt) => {
                                        const updatedList = [...listOfValues]
                                        updatedList[index].isSelected = true
                                        console.log("Updated list", updatedList)
                                        onChange({ value: updatedList, dataAttribute })

                                    }
                                    }
                                    name={option.value}
                                    {...validateField(option.value, dataAttribute, validation)}
                                />
                            }
                            label={option.label}
                        />
                    )
                ))}
            </FormGroup>
        </div>
    )

};