import "../App.css";
import headerLogo from "../images/AppLogo.png";
import { useNavigate } from "react-router-dom";
import headerImage from "../images/HeaderImage.png";
import homeIcon from "../images/HomeIcon.png";
import InputLabel from "@mui/material/InputLabel";
import * as React from "react";
import { useThemeContext } from "../context/ThemeContext";
function AppHeader() {
    const register = "Register >";
    const login = "Login";
    const your_banking = 'Your banking';
    const easier_than_ever = 'easier than ever';
    const navigate = useNavigate();
    const { getTheme } = useThemeContext()
    const theme = getTheme()

    //mock-data
    const listItems = [
        "Investment & Saving",
        "Everyday Banking",
        "Wealth Management",
        "Financing",
        "Payments & Transfers",
        "Ways to Bank",
    ];

    const listItem = listItems.map((item) => (
        <li key={item} onClick={() => onListItemClick(item)}>{item}</li>
    ));

    let breadcrumbArray = ["Home"]; //Default item
    const [breadcrumbItems, setBreadcrumbItems] = React.useState(breadcrumbArray);

    function onLoginClick() {
        navigate("/admin/login");
        console.log("Login clicked !");
    }

    function onRegisterClick() {
        console.log("Register clicked !");
    }

    function onListItemClick(item) {
        console.log("ListItem", item, "clicked !");
        if (item) {
            breadcrumbArray = ['Home'];
            breadcrumbArray.push(' / ' + item);
        }
        setBreadcrumbItems(breadcrumbArray);
    }

    // function onBreadcrumbClick(item) {
    //     console.log("Breadcrumb", item, "clicked !");
    // }

    return (
        <header className="header">
            <div className="app-nav-bar">
                <div className="left-menu">
                    <select name="selectedPersonal" className="dropdown">
                        <option value="p" className="option">
                            Personal
                        </option>
                        <option value="p1" className="option">
                            Personal Details 1
                        </option>
                        <option value="p2" className="option">
                            Personal Details 2
                        </option>
                        <option value="p3" className="option">
                            Personal Details 3
                        </option>
                    </select>
                </div>

                <div className="right-menus">
                    <select name="country" className="dropdown">
                        <option value="c" className="option">
                            Country
                        </option>
                        <option value="c1" className="option">
                            India
                        </option>
                        <option value="c2" className="option">
                            United Kingdom
                        </option>
                        <option value="c3" className="option">
                            Northern Ireland
                        </option>
                    </select>

                    <InputLabel className="input-label" onClick={onRegisterClick}>
                        {register}
                    </InputLabel>
                    <InputLabel
                        className="input-label"
                        style={{ backgroundColor: theme?.color?.primary }}
                        onClick={onLoginClick}
                    >
                        {login}
                    </InputLabel>
                </div>
            </div>

            <div className="header-section">
                <div className="app-logo">
                    <img src={headerLogo} alt="Asparagus Bank logo" style={{ cursor: 'pointer' }} />
                </div>

                <div className="app-list-items">
                    <ul className="list-items">{listItem}</ul>
                </div>

                <div className="app-header-img">
                    <img
                        className="img"
                        src={headerImage}
                        alt="Asparagus Bank header"
                    >
                    </img>
                    <div className="text-on-image">
                        <h1 className='big-font'>{your_banking}</h1>
                        <h1>{easier_than_ever}</h1>
                    </div>
                </div>

                <div className="app-breadcrumbs">
                    <img className="icon" src={homeIcon} alt="Home icon"></img>
                    <ul className="breadcrumb-list-items">
                        <li onClick={() => window.location.reload()}>
                            {breadcrumbItems}
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
}

export default AppHeader;