import React, { useEffect } from 'react';
import { DynamicPage } from '../../components/dynamicPage';
import { LayoutLoader } from '../../layoutEngine/layoutLoader';
import { useDraftContext } from "../../context/DraftDataContext";

const Preview = (props) => {
    const [data, setData] = React.useState();
    const { style, myData } = props;
    const { notifyChange } = useDraftContext()

    const parentId = myData?.name ?? 'previewData'

    const resetPros = {
        parentId: parentId,
        value: ''
    }
    
    
    useEffect(() => {
        let metadata = myData?.metadata;
        metadata = metadata && typeof metadata === 'string' ? JSON.parse(metadata).metadata : metadata?.metadata;
        notifyChange( resetPros );
        setData(metadata);
    }, [myData?.metadata]);



    if (data) {
        return (
            <DynamicPage>
                {data &&
                    <LayoutLoader id="Preview" metadata={data} nodata={true} parentId={parentId} />
                }
            </DynamicPage>
        );
    }
    return <label id='preview' style={style}>Preview to verify</label>;
};

export default Preview;