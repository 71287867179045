import React, { createContext, useContext } from "react";

const actionsContext = createContext({
    subscribe: (id, callback = (msg) => { }) => { },
    unsubscribe: (id) => { },
    setDataContext: (name, dataContext) => { },
    updateData: (name, dataContext) => { },
    getData: (name) => { },
    getDataContext: (name) => { }
}
)

const useActionsContext = () => {
    const context = useContext(actionsContext)
    return context
}

const ActionsContext = ({children, ...prop}) => {
    const callbacks = []
    // const dataContextState = { current: {} }

    const addActionSubscriber = (subscribers) => (id, callback = (msg) => { }) => {
        const existingSub = subscribers.find(sub => sub.id === id)
        if (!existingSub) {
            subscribers.push({ id, callback, count: 1 })
        }
    }

    const removeActionSubscriber = (subscribers) => (id) => {
        const existingSub = subscribers.find(sub => sub.id === id)
        if (existingSub && existingSub.count === 1) {
            subscribers.splice(subscribers.findIndex((sub) => sub.id === id), 1)
        }
    }

    const updateData = (subscribers) => (a, b) => {
        console.log(a, b)
    }

    const getData = (subscribers) => (a, b) => {
        console.log(a, b)
    }

    return (
        <actionsContext.Provider value={{
            subscribe: addActionSubscriber(callbacks),
            unsubscribe: removeActionSubscriber(callbacks),
            updateData: updateData(callbacks),
            getData: getData(callbacks),
        }}>
            {children}
        </actionsContext.Provider>
    )
}

export { ActionsContext, useActionsContext }