
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { validateData } from "../../layoutEngine/validator";
import { InputLabel, FormControl, FormHelperText } from "@mui/material";
import { useDraftContext } from "../../context/DraftDataContext";
import { performActions } from "../../helpers/ActionExecuter";
import Autocomplete from '@mui/material/Autocomplete';
export default function AutoComplete(props) {
    const { dataAttribute, validation, validate, defaultValue, label, action, functions,
        className, options = [], parentId } = props

    const [value, setValue] = useState(defaultValue || '')
    const [errorState, setErrorState] = useState(true);


    const { notifyChange, notifyError } = useDraftContext()


    const onChange = (obj) => {
        notifyChange({ ...obj, parentId });
        setValue(obj.value);
        setErrorState(false);
        if (action?.onChange) performActions(action.onChange, functions, obj.value);

    }
    const validateField = (value, attr, constraints) => {
        const valid = validateData(value, attr, constraints)
        console.log({ validate, action })
        notifyError(attr, { error: valid, msg: constraints?.message })
        if (action?.validate || validate) {
            if (!valid) return {}
            return {
                error: true,
                helperText: constraints?.message
            }
        }
    }

    // useEffect(() => {
    //     const validationResults = validateField(value, dataAttribute, validation);
    //     setErrorState(validationResults);
    // }, [value, dataAttribute, validation]);

    return (
        <div className="aligned_form" >
            <InputLabel >{label}</InputLabel>
            <FormControl fullWidth error={errorState.error}>

                <Autocomplete style={{ width: '100%' }}
                    onChange={(evt) => onChange({ value: evt.target.value, dataAttribute })}
                    disablePortal
                    id="outlined-textarea"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                    {...validateField(value, dataAttribute, validation)}
                />
                {validate && errorState && (
                    <FormHelperText>{validation?.message}</FormHelperText>
                )}
            </FormControl>
        </div>
    )

}