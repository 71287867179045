export const performActions = (actions, functions, value, props) => {
    if(!actions) return
    const actionsArr = actions.split('>')
    let counter = 0
    let dValue = value
    const execute = (action) => {
        if (action.startsWith('^')) {
            const parentAction = action.slice(1, action.length)
            if(props?.[parentAction]) {
                dValue = props[parentAction].params
            }
            functions[parentAction](dValue, () => {
                counter = counter   + 1
                if (actionsArr[counter]) {
                    execute(actionsArr[counter])
                }
            }, value)
        } else {
            action()
        }
    }
    execute(actionsArr[0])
}