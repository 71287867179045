import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { validateData } from "../../layoutEngine/validator";
import { InputLabel, FormControl, FormHelperText } from "@mui/material";
import { useDraftContext } from "../../context/DraftDataContext";
import { performActions } from "../../helpers/ActionExecuter";

export default function Dropdown(props) {
    const { dataAttribute, validation, label, action, functions, data, nodata, validate,
        className, options = [], parentId } = props

    const defaultValue = nodata && data?.[parentId]?.[dataAttribute] !== '' ? data?.[parentId]?.[dataAttribute] : ''

    const [value, setValue] = useState(defaultValue || '')
    const [errorState, setErrorState] = useState(true);

    const { notifyChange, notifyError } = useDraftContext()

    const onChange = (obj) => {
        notifyChange({ ...obj, parentId });
        setValue(obj.value);
        setErrorState(false);
        if (action?.onChange) performActions(action.onChange, functions, obj.value);

    }


    const validateField = (value, attr, constraints) => {
        const valid = validateData(value, attr, constraints)
        notifyError(attr, { error: valid, msg: constraints?.message })
        if (action?.validate || validate) {
            if (!valid) return {}
            return {
                error: true,
                helperText: constraints?.message
            }
        }
    }


    return (
        <div className="aligned_form" >
            <InputLabel >{label}</InputLabel>
            <FormControl fullWidth>
                <Select className={className} style={{ height: "60px", width: '100%' }}
                    value={value}
                    onChange={(evt) => onChange({ value: evt.target.value, dataAttribute })}
                    {...validateField(value, dataAttribute, validation)}
                >
                    {Array.isArray(options) && options?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>

                {validate && errorState && (
                    <FormHelperText>{validation?.message}</FormHelperText>
                )}
            </FormControl>
        </div>
    )

}