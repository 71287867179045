import React, { createContext, useContext } from "react";
import { post } from "../services/dataServices";

let appData = {}

let errorData = { }
const draftDataContext = createContext({
    subscribe: (id, callback = (msg) => { }) => { },
    unsubscribe: (id) => { },
    notifyChange: (ref, data, dataAttribute) => { },
    notifyError: (ref, data, dataAttribute) => { },
    postData: (data) => { },
    getDraftData: (ref) => { },
    resetData: () => { }
}
)

const useDraftContext = () => {
    const context = useContext(draftDataContext)
    return context
}

const DraftDataContext = ({ children, ...prop }) => {
    const callbacks = []

    const addDataStateSubscriber = (subscribers) => (id, callback = (msg) => { }) => {
        const existingSub = subscribers.find(sub => sub.id === id)
        if (!existingSub) {
            subscribers.push({ id, callback, count: 1 })
        }
    }

    const removeDataStateSubscriber = (subscribers) => (id) => {
        const existingSub = subscribers.find(sub => sub.id === id)
        if (existingSub && existingSub.count === 1) {
            subscribers.splice(subscribers.findIndex((sub) => sub.id === id), 1)
        }
    }

    const getDarft = (subscribers) => (id) => {
        return id ? appData[id] : appData
    }

    const resetData = (subscribers) => (id) => {
        appData = id ? { ...appData, [id]: {} } : {};
        errorData = id ? errorData : {};
    }
    

    const postData = (subscribers) => (data, callback) => {
        post(`https://mazizhls58.execute-api.eu-west-2.amazonaws.com/prod/submit`, { data }, null, callback).then((res) => {
            callback(res)
        })

    }

    const notifyChange = (subscribers) => (data) => {
        const { parentId, dataAttribute, value } = data
        if (!appData[parentId] || dataAttribute === undefined) appData[parentId] = {}
        else appData[parentId][dataAttribute] = value
    }

    const notifyError = (subscribers) => (attr, { error, msg }) => {
        errorData[attr] = { error, msg }
    }

    const getError = (subscribers) => () => {
        let appError = false
        const errors = {}
        Object.keys(errorData).forEach(key => {
            appError = errorData[key].error;
            if (appError && Object.keys(appError).length > 0) {
                errors[key] = appError;
            }
        })
        console.log(errors)
        return  Object.keys(errors).length !== 0
    }

    return (
        <draftDataContext.Provider value={{
            subscribe: addDataStateSubscriber(callbacks),
            unsubscribe: removeDataStateSubscriber(callbacks),
            getDraftData: getDarft(callbacks),
            postData: postData(callbacks),
            notifyChange: notifyChange(callbacks),
            notifyError: notifyError(callbacks),
            getError: getError(callbacks),
            resetData: resetData(callbacks)
        }}>
            {children}
        </draftDataContext.Provider>
    )
}

export { DraftDataContext, useDraftContext }
