import { useEffect, useState } from "react";
import config from "../config/config";
import { LayoutLoader } from "../layoutEngine/layoutLoader";
import { convertToObjectsArray, arrayFinder } from "../helpers/utils";

import { post } from "../services/dataServices";
import meta from "./meta.json";
import "./index.css";
import { useDraftContext } from "../context/DraftDataContext";
import { fetchPrompts } from "../services/fetchPrompts";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export const PrompterV2 = () => {
    const { createPrompt, uploadPrompt, getPrompt, getCreatePromptResponse } = config;
    const { getDraftData, resetData } = useDraftContext();
    const [myData, setMyData] = useState("");
    const [fetcListFlag, setFetchListFlag] = useState(true);
    const [processedPrompts, setProcessedPrompts] = useState();
    const [loading, setLoading] = useState(false);
    const parentId = "prompterV2";
    const promptMetadata = { ...meta, parentId };
    const navigate = useNavigate();
    const interval = 5000
    const commonErrMsg = 'Something went wrong!!';

    useEffect(() => {
        // // Get List prompts, store the values in Local storage and state 
        if (fetcListFlag) {
            setFetchListFlag(false);
            setLoading(true)
            fetchPrompts().then(data => {
                if (data) {
                    const promptsList = convertToObjectsArray(data?.body?.promptsList)
                    setProcessedPrompts(promptsList);
                    setMyData((prevData) => ({ ...prevData, prompts: promptsList }));
                    setLoading(false)
                }
            }).catch(err => {
                console.log(err)
                alert(commonErrMsg)
                setLoading(false)
            })
        }
    }, [fetcListFlag]);

    const pollAIResponse = (refId) => {
        const poll = () => {
            post(getCreatePromptResponse, { refId })
                .then(res => {
                    const promptMetaData = res.data?.body;
                    if (promptMetaData) {
                        localStorage.setItem('prompt', JSON.stringify({
                            name: promptMetaData?.name,
                            prompt: promptMetaData?.prompt,
                            metadata: promptMetaData?.llmResponse.metadata,
                        }));

                        setMyData({
                            ...res.data.body,
                            metadata: promptMetaData?.llmResponse,
                        });

                        // Stop after success response
                        clearInterval(pollInterval)
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log(err)
                    clearInterval(pollInterval)
                    setLoading(false)
                })
        }
        const pollInterval = setInterval(poll, interval)
    }

    const submitPrompt = async () => {
        const data = getDraftData(parentId);
        if (data) {
            try {
                setLoading(true)
                const { refId } = await post(createPrompt, { name: data.name, prompt: data.prompt }).then(res => res.data?.body)
                // Poll the get call here
                pollAIResponse(refId)
            }
            catch (e) {
                console.log(e);
                alert(commonErrMsg)
                setLoading(false)
            }
        }
    };

    const setSelectedPrompt = (value) => {
        const selected = arrayFinder(processedPrompts, value)

        if (selected) {

            resetData()

            const storedLS = JSON.parse(localStorage.getItem('prompt'));
            if (storedLS && storedLS.name === selected.value) {
                setMyData({ ...myData, name: storedLS?.name, prompt: storedLS?.prompt, metadata: storedLS.metadata });
            }
            else {
                setLoading(true)
                post(getPrompt, { 'name': value })
                    .then(res => {
                        const resMetaData = res.data?.body;
                        localStorage.setItem('prompt', JSON.stringify({
                            name: resMetaData?.aspName,
                            prompt: resMetaData?.aspPrompt?.prompt,
                            metadata: resMetaData.aspMetaData,
                        }));
                        setMyData({ ...myData, name: resMetaData?.aspName, prompt: resMetaData?.aspPrompt?.prompt, metadata: resMetaData.aspMetaData });
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        alert(commonErrMsg)
                        setLoading(false);
                    })
            }
        }
    };



    const pageRedirect = () => {
        const { name } = myData;
        const url = `/${name}`
        navigate(url, { replace: true, state: myData });
    }

    const saveMetadata = () => {
        const { name, metadata, prompt } = myData;
        const metadataStr = JSON.stringify(metadata);
        if (name && metadata && prompt) {
            setLoading(true);
            post(uploadPrompt, { name, metadata: metadataStr, prompt })
                .then((res) => {
                    localStorage.clear();
                    console.log("Metadata saved!");
                    setFetchListFlag(true)
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    alert(commonErrMsg)
                    setLoading(false);
                });
        }
    };

    const refreshPreview = () => {
        setMyData({ ...myData, metadata: getDraftData(parentId).metadata });
        console.log('refresh view')
    }

    const onTabChange = () => {
        setMyData()
    }

    const functions = { saveMetadata, submitPrompt, refreshPreview, setSelectedPrompt, pageRedirect, onTabChange };
    return (
        <>
            {loading ? <div className="page-loader"><CircularProgress className="loader" /></div> : <></>}
            <LayoutLoader id={"indexv2"} {...promptMetadata} myData={myData} functions={functions} />
        </>
    );
};
