import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { validateData } from "../../layoutEngine/validator";
import { useDraftContext } from "../../context/DraftDataContext";
import { performActions } from "../../helpers/ActionExecuter";
export default function TextInput(props) {
  const { notifyChange, notifyError, getDraftData } = useDraftContext();
  const { dataAttribute, validation, label, className, parentId, action, validate, myData, data, functions, nodata } = props
  let defaultValue = '';

  if( parentId === 'prompterV2') {
    defaultValue= data?.dataAttribute;
    if( defaultValue ) {
       
      this.validateField(defaultValue, dataAttribute, validation)
    }
  }
  else defaultValue = nodata && ( parentId && dataAttribute) ? getDraftData(parentId)?.[dataAttribute] : '';

  const [value, setValue] = useState(defaultValue)
  const asteriskSymbol = '*';
  const onChange = (obj) => {
    notifyChange({ ...obj, parentId })
    if(action?.onChange) performActions(action.onChange, functions)
    setValue(obj.value)
  }


  const validateField = (value, attr, constraints) => {
    const valid = validateData(value, attr, constraints)
    notifyError(attr, { error: valid, msg: constraints?.message })
    if (action?.validate || validate) {
      if (!valid) return {}
      return {
        error: true,
        helperText: constraints?.message
      }
    }
  }

  const labelField = validation?.allowEmpty ? label : (label + ' ' + asteriskSymbol);

  return (
    <div className="aligned_form">
      <label>{labelField}</label>
      <TextField
        id="outlined-textarea"
        style={{ width: "100%" }}
        value={defaultValue}
        key={`${parentId}'_+_'${dataAttribute}`}
        className={className}
        onBlur={(evt) =>
          onChange({ value: evt.target.value, dataAttribute })
        }
        onChange={(evt) =>
          onChange({ value: evt.target.value, dataAttribute })
        }
        {...validateField(value, dataAttribute, validation)}
      />
    </div>
  );

}
