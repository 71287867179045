import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';

import { useState } from 'react';

const styles = theme => ({
    textField: {

    },
    input: {
        color: 'white'
    }
});

export const InputField = ({ id, label, type, sx, className, onChange, handleChange, value }) => {
    const [dvalue, setDValue] = useState(value)

    useEffect(() => {
        setDValue(value)
    }, [value])

    return (
        <Box sx={{ display: 'grid' }}>
            <label
                htmlFor={id}
                style={{

                    fontSize: "18px",
                    marginBottom: "10px",
                    fontWeight: 500

                }}
                className='input-label'
            >{label} </label>

            <TextField
                className={styles.textField}

                sx={{
                    "& .MuiOutlinedInput-root": {
                        color: "#000",
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        marginBottom: '25px',
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#BDBEC2",
                            borderWidth: "2px",
                            borderRadius: 2,
                            boxShadow: "1px 3px 5px #BDBEC2"
                        },
                    },

                    "& .MuiInputLabel-outlined": {
                        color: "#2e2e2e",
                        fontWeight: "bold",

                    },
                    ...sx
                }}
                type={type}
                id={id}
                variant="outlined"
                value={dvalue}
                onChange={(e) => {
                    setDValue(e.target.value);
                    if (handleChange)
                        handleChange(e.target.value)
                }}
            />
        </Box>
    )
}