import { useDraftContext } from "../../../context/DraftDataContext";
import { performActions } from "../../../helpers/ActionExecuter";
import Editor from "./Editor";

export default function JEDIT(props) {
    const { dataAttribute, parentId, action, myData, data, functions } = props
    const defaultValue = data || myData?.[dataAttribute] || {}
    const { notifyChange } = useDraftContext()

    const onChange = ({ text, json, dataAttribute }) => {
        try {
            const obj = { value: json, dataAttribute, parentId }
            if (text) {
                obj.value = JSON.parse(text)
            }
            notifyChange(obj)
            if (action?.onChange) performActions(action.onChange, functions)
        } catch (e) {
            console.log("Invalid JSON")
        }

    }

    return (
        <div style={{ height: '350px' }}>
            <Editor
                content={{ json: defaultValue }}
                parentId={parentId}
                mode="text"
                readOnly={false}
                onChange={({ text, json }) => onChange({ text, json, dataAttribute })}
            />
        </div>
    );
}
