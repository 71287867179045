import React, { createContext, useContext, useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material";
import { getAppTheme } from "../themes/theme";

const themeContext = createContext({
    setTheme: () => { },
    getTheme: () => { }
}
)

const useThemeContext = () => {
    const context = useContext(themeContext)
    return context
}

const ThemeContext = ({ children }) => {

    const [theme1, setTheme1] = useState(null)
    useEffect(() => {
        if (!theme1) {
            getAppTheme('light').then((res) => {
                setTheme1(res)
            })
        }
    }, [theme1])

    const getTheme = () => () => {
        return theme1
    }

    const setTheme = () => (value) => {
        setTheme1(value)
    }


    return (
        <themeContext.Provider value={{
            getTheme: getTheme(),
            setTheme: setTheme()
        }}>
            {theme1 &&
                <ThemeProvider theme={theme1}>
                    {children}
                </ThemeProvider>
            }
        </themeContext.Provider>
    )
}

export { ThemeContext, useThemeContext }
