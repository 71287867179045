// Function to set a value in local storage
function setLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

// Function to get a value from local storage
function getLocalStorage(key) {
    const value = localStorage.getItem(key);
    return value && value !== "undefined" ? JSON.parse(value) : null;
}

const validateJson = (json) => {
    let str;
    try {
        str = typeof json === "string" && json.length > 0 ? JSON.parse(json) : json;
        return str && typeof str === "object";
    } catch (e) {
        return false;
    }

};

function evaluateCondition(condition, myData, allData, propName) {
    try {
        const conditionFn = new Function('myData', 'allData', 'propName', `return ${condition}`);
        const result = conditionFn(myData, allData, propName);
        return result
    } catch (error) {
        console.log('Error evaluating condition:', error);
        return false;
    }
}

const resolveRule = (ruleStr, data, defaultValue, functions) => {
    const extractRule = ruleStr.substring(3, ruleStr.length - 1)
    if (extractRule.startsWith('function')) {
        const fn = new Function('myData', 'allData', 'propName', 'functions', extractRule)
        return fn(data.myData, data.allData, data.propName, functions)
    } else {
        const { myData, allData, propName } = data || { myData: null, allData: null, propName: null }
        return evaluateCondition(extractRule, myData, allData, propName, defaultValue)
    }
}

const convertToObjectsArray = (arr) => arr.map(item => ({ value: item, label: item }));

const arrayFinder = (array, value) => array.find(item => item.value === value);

const filterByValue = (array, searchTerm) => array.filter(item => item.value.toLowerCase().includes(searchTerm.toLowerCase()));



export {
    setLocalStorage as setLS,
    getLocalStorage as getLS,
    validateJson,
    resolveRule,
    evaluateCondition,
    convertToObjectsArray,
    arrayFinder,
    filterByValue
};