export const colors = {
    primary: '#71bf4f',
    secondary: '',
    text: '#1B1212',
    error: '#e74c3c',
    success: '#27ae60',
    lightgray: '#bdc3c7',
    darkgray: '#7f8c8d',
    black: '#000',
    white: '#fff',
    purpleColor: '#8B4C88',
}